<template>
    <section class=" leaves-background ">
        <div class="content-wrapper mb-5">
            <div class="d-flex justify-content-end">
                <h1>Votre panier</h1>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h3>Soins</h3>
                    <b-table :items="items">
                        <template #cell(action)>
                            <i class="fa fa-trash mx-2"></i>
                            <i class="fa fa-pencil"></i>
                        </template>
                    </b-table>

                    <h3 class="mt-4">Articles</h3>
                    <b-table :items="items">
                        <template #cell(action)>
                            <i class="fa fa-trash mx-2"></i>
                            <i class="fa fa-pencil"></i>
                        </template>
                    </b-table>
                </div>
                <div class="col-md-12 d-flex justify-content-end">
                    <h3 class="mr-5">Total: 40€</h3>
                </div>
                <div class=" col-md-12 d-flex justify-content-center">
                    <button class="btn btn-primary" @click="showAlert()">Valider votre panier</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'cart',
    data () {
        return {
            items: [
                { Prestation: 'Epilation des jambes', Duree: '2h', Date: '20 Mai', Heure: '', Tarif:'2€', Action: ''},
                { Prestation: 'Epilation des jambes', Duree: '2h', Date: '20 Mai', Heure: '', Tarif:'2€', Action: ''},
                { Prestation: 'Epilation des jambes', Duree: '2h', Date: '20 Mai', Heure: '', Tarif:'2€', Action: ''}
                ],
            total: [
                { Total: 'Total', Duree:'', Date:'', Heure:'', Tarif: '40€', Action: ''}
            ],
            date: ''
            }
        },
        methods: {
            showAlert () {
            if (this.date) {
                this.$swal(
                    'Enregistré!',
                    'Votre prestation a été ajoutée au panier',
                    'success'
                )
            }else {
                this.$swal({
                    title: 'Date manquante',
                    text: 'Veuillez compléter toutes les dates de toutes les prestations'
                    })
            }
            }
        }
    }
</script>

<style scoped>
    .leaves-background {
        position: relative;
        min-height: 30rem;
        background-image: url('../assets/images/import/leaves.png');
        background-size: 107%;
        background-position:top -42rem right -21rem;
        background-repeat: no-repeat;
    }
</style>